import { race, put, take, delay } from 'redux-saga/effects'
import { fetchAPIData } from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { MAM_LOCATIONS_ENDPOINT,MAMMO_VISIT_REGULAR } from '../../shared/constants/api-helpers.js'

export default function * mamSearchSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_MAM_LOCATIONS')
    let params = action.params ? Object.assign({}, action.params) : null
    params.endPoint = MAM_LOCATIONS_ENDPOINT

    yield put({ type: 'SEARCH_LOCATIONS' })// sets a boolean for data load to true
    yield put({ type: 'CLEAR_LOCATIONS' })// clears providers and sets dataLoaded to false
    // yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'A TO Z' })// sets sort order
    yield put({ type: 'SET_LOCATIONS_CALL_FAILED', value: false })
    if (!params.VisitTypeId) { 
      params.VisitTypeId = window.sessionStorage.getItem('VisitTypeID')|| MAMMO_VISIT_REGULAR
    }

    const { posts } = yield race({
      posts: fetchAPIData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.Success) {
      let cleanLocations = posts.MammoLocations
      let sortedLocations = sortLocations(cleanLocations)
      window.sessionStorage.setItem('visitDuration', posts.Duration)      
      yield put({ type: 'SET_LOCATIONS', value: sortedLocations })
      if (params.lat && params.lng && params.dist) {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'Distance' })
      }
      else {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'A TO Z' })
      }
      // else if (sortedLocations && sortedLocations.length && sortedLocations[0].Distance) {
      //   yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'Distance' })
      // }
    } else {
      yield put({ type: 'SET_LOCATIONS_CALL_FAILED', value: true })
    }
  }
}

const sortLocations = (locations) => (
  locations.sort((a, b) => {
    return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
  })
)
